global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
global.Modernizr = require('modernizr');

require('jquery.cookie');
require('cookieCuttr/jquery.cookiecuttr');
require('jquery-migrate');
require('jquery-shorten');
require('jquery-match-height');
require('jquery-validation');

require('bootstrap-sass');

require('bootstrap-datetime-picker');

require('redcurrents-bentokit/src/javascripts/buckle');
require('redcurrents-bentokit/src/javascripts/compat');
require('redcurrents-bentokit/src/javascripts/collapse-extended');
require('redcurrents-bentokit/src/javascripts/collapse-scrollto');
require('redcurrents-bentokit/src/javascripts/jquery.navbar-resizer');
require('redcurrents-bentokit/src/javascripts/offie');
require('redcurrents-bentokit/src/javascripts/front/_event');
require('redcurrents-bentokit/src/javascripts/front/_publication_collapses');
require('redcurrents-bentokit/src/javascripts/front/_publication_responsive');

global.MatchHeightHelper = require('./scripts/matchheight-update');

require('./scripts/svgsprite');

require('./scripts/modules/gallery-deck');
require('./scripts/modules/offie-collapse');
require('./scripts/_client_disclaimer');
require('./scripts/main');

require('./media--all.scss');
