$(function () {
  var cookieName = 'clientDisclaimer';
  var cookie;
  
  if (!$.cookie(cookieName)) {
    cookie = {
      disclaimer: true,
    };
    
    $.cookie(cookieName, JSON.stringify(cookie));
  } else {
    cookie = JSON.parse($.cookie(cookieName));
  }

  if (cookie && false === cookie.disclaimer) {
    return;
  }

  $('.js-client-disclaimer').modal({
    backdrop: 'static',
    keyboard: false,
  });

  $('.js-client-disclaimer').modal('show');

  $('.js-client-disclaimer [data-redirect]').on('click', function() {
    window.location.href = $(this).attr('data-redirect');
  });

  $('.js-client-disclaimer .js-disclaimer-confirm').on('click', function (event) {
    cookie.disclaimer = false;
    $.cookie(cookieName, JSON.stringify(cookie));
    
    $('.js-client-disclaimer').modal('hide');
  });
});
